<template>
    <div style="background:white">
 
        <div :style="{height:subHeight,'overflow-y':'auto'}">  
            <div  v-for="(gpitem,index) in tabFrmCol" :key="index" style="margin-bottom:5px;"> 
                <div   class="one-toolbar">
                    <!-- <span style="font-weight:600;font-size:14px;color:#fff; padding-left:3px"> -->
                        <span  class="spanRow">{{gpitem.gpnm}}</span> 
                    <!-- </span>  -->
                    <el-link v-if="gpitem.gpno==='20284'" :underline="false" @click="perAdd" style="color:white">【<i class="iconfont icon-xinzeng"></i> 新增记录】</el-link> 
                    <el-link v-if="gpitem.gpno==='20210'" :underline="false" @click="bankAdd" style="color:white">【<i class="iconfont icon-xinzeng"></i> 新增记录】</el-link> 
                </div>
                <!-- 联络记录:20284,图片资料:38752  -->
                <span v-if="gpitem.gpno!=='20284' && gpitem.gpno!=='38752' && gpitem.gpno!=='20210'">
                    <Form :model="matRow" :label-width="110" ref="matWin"  label-position="right" inline :rules="custRule" >  
                        <span v-for="(item,index) in gpitem.gplist" :key="index" >
                        <FormItem  :label="item.FLDNM"  :class="(item.FLDNO=='RMK'||item.FLDNO=='EPROFILE')?'row-width':(item.FLDNO=='PRDTY'||item.FLDNO=='REQPRDTY')?'item-width2':'item-width' " v-if="item.FHIDE==='N'" :prop="item.FLDNO"  > 
                            <el-date-picker type="year"  v-if="item.EDCSS==='date'&item.FLDNO==='BLDYEAR'" v-model="matRow['BLDYEAR']" clearable value-format="yyyy" style="width:100%" ></el-date-picker>                 
                            <!-- <el-date-picker type="date"  v-if="item.EDCSS==='date'" v-model="matRow[item.FLDNO]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>    -->
                            <el-input v-enter-number   v-else-if="item.EDCSS==='number'" v-model="matRow[item.FLDNO]" style="width:100%" ></el-input>   
 
                            <el-select   v-else-if="item.FLDNO==='CORPTY'" v-model="matRow[item.FLDNO]" style="width:100%" >
                                <el-option v-for="item in corpArr" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select> 
                            <el-select   v-else-if="item.FLDNO==='SUPSRC'" v-model="matRow[item.FLDNO]" style="width:100%" >
                                <el-option  v-for="item in lineArr" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select> 
                            <el-select   v-else-if="item.FLDNO==='PAYMTH'" v-model="matRow[item.FLDNO]" style="width:100%" >
                                <el-option  v-for="item in payArr" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select>   
                            <el-cascader v-else-if=" item.FLDNO==='AREA'"  style="width:100%"
                                v-model="matRow[item.FLDNO]" 
                                clearable
                                filterable
                                :options="countryArr">
                            </el-cascader>
                            <el-select v-loadmore:rangeNumber="loadMore(rangeNumber)" multiple filterable remote v-else-if="item.FLDNO==='PRDTY'||item.FLDNO==='REQPRDTY'" v-model="matRow[item.FLDNO]" style="width:100%" >
                                <el-option  v-for="item in prdtyArr.slice(0,rangeNumber)" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select> 
                            <el-input  type="textarea" v-else-if="item.FLDNO=='RMK'||item.FLDNO=='EPROFILE'" :rows="6" v-model="matRow[item.FLDNO]"> </el-input>
                            <el-input  type="text" v-else :disabled="item.DISABLED==='Y'||item.key=='MAILADDR'?true:false "  v-model="matRow[item.FLDNO]">
                                <span v-if="gpitem.gpno==='38818'" slot="prepend">HTTP://</span>
                            </el-input>
                        </FormItem>
                        </span>
                    </Form>
                </span>
                <!-- 产品图片:38752  -->
                <span v-if="gpitem.gpno==='38752'"> 
                    <div style="display:flex" >
                        <el-row style="width:100%" :gutter="6">
                            <el-col :span="8" v-for="(item,index) in imageTypeArr" :key="index">
                                <el-card >
                                    <span slot="header">{{item.CNAME}}</span>
                                    <div style="float:left">
                                        <div v-for="(item1,index) in videoData" :key="index" :label="item1.title"  style="float:left; ">
                                            <el-row>
                                                <el-image v-if="item1.FILETY==item.CODE" fit="scale-down" :src="item1.FILEPATH" style="width:90px;height:90px;margin-right:20px;" ></el-image>
                                            </el-row> 
                                            <el-row >
                                                <el-link v-if="item1.FILETY==item.CODE" :underline="false" @click="imgDel(item1.IDSEQ,item1.LSTSEQ,item1.FILEPATH,index)"><i class="iconfont icon-shanchu1" />  </el-link>
                                                <el-link v-if="item1.FILETY==item.CODE" :underline="false" @click="imgPreview(item1.FILEPATH)"><i class="iconfont icon-chazhao" style="margin-left:60px" />  </el-link>
                                            </el-row>                                                
                                        </div> 
                                        <div  style="float:left">
                                            <el-row>
                                                <el-link :underline="false"  >
                                                    <Upload
                                                        ref="upload"
                                                        name="upfile"
                                                        :data="uploadData"
                                                        :show-upload-list="false"
                                                        :before-upload="beforeImgFile"
                                                        :on-success="successImgFile"
                                                        :on-progress="handleProgress"
                                                        :accept="Accept"   
                                                        :disabled="!saveFlag"                      
                                                        :format="['jpg','jpeg','png','gif','webp' ]"
                                                        :max-size="2048"
                                                        :on-format-error="handleFormatError"
                                                        :on-exceeded-size="handleMaxSize"
                                                        :action="uploadUrl"
                                                        style="display: inline-block;width:90px; ">                           
                                                        <div style="padding: 1px 0;width:90px;height:90px;">
                                                            <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" @click="showClick(item.CODE)"></el-image> 
                                                        </div>                                                       
                                                    </Upload> 
                                                </el-link>
                                            </el-row>
                                            <el-row >
                                                <i class="iconfont icon-shangchuan1" style="margin-left:30px;"/> 
                                            </el-row>
                                        </div>                                         
                                    </div>
                                </el-card>
                            </el-col>
                        </el-row>   
                    </div>
                </span>
                <!-- 银行信息:20210  -->
                <span v-if="gpitem.gpno==='20210'"> 
                  <el-table  :data="bankData" 
                    border                            
                    size="small"
                    ref="pertab"
                    :height="220" 
                    highlight-current-row> 
                    <el-table-column type="index" width="60" label="序号"></el-table-column>
                    <el-table-column  v-for="(item ) in bankCol.filter(item=>item.thide=='N')" :key="item.index"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        :sortable="item.key!=='RN'"
                        :min-width="item.width">
                        <template slot-scope="scope" >
                            <el-checkbox  v-if ="item.edcss=='checkbox'" disabled true-label="Y"  false-label="N" v-model="scope.row[item.key]" ></el-checkbox>
                            <span v-else v-text="scope.row[item.key]"></span>
                        </template>
                    </el-table-column>  
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="135">
                        <template slot-scope="scope">
                            <el-button size="small"   type="text" icon="el-icon-edit" @click="bankEdit(scope.row,scope.$index)">编辑</el-button>
                            <el-button size="small"   type="text" icon="el-icon-delete" @click="bankDel(scope.row,scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>                        
                  </el-table>
                </span>
                <!-- 联络记录:20284  -->
                <span v-if="gpitem.gpno==='20284'"> 
                  <el-table  :data="perData" 
                    border                            
                    size="small"
                    ref="pertab"
                    :height="250" 
                    highlight-current-row> 
                    <el-table-column  v-for="(item ) in perCol.filter(item=>item.thide=='N')" :key="item.index"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        :sortable="item.key!=='RN'"
                        :min-width="item.width">
                        <template slot-scope="scope" >
                            <el-checkbox  v-if ="item.edcss=='checkbox'" disabled true-label="Y"  false-label="N" v-model="scope.row[item.key]" ></el-checkbox>
                            <span v-else v-text="scope.row[item.key]"></span>
                        </template>
                    </el-table-column>  
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="135">
                        <template slot-scope="scope">
                            <el-button size="small"  type="text" icon="el-icon-edit" @click="perEdit(scope.row,scope.$index)">编辑</el-button>
                            <el-button size="small"  type="text" icon="el-icon-delete" @click="perDel(scope.row,scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>                        
                  </el-table>
                </span>
            </div>
        </div>
        <div class="bottomdiv">
            <div style="width:116px;font-size:18px;float:right  ">
                <el-button   class="paybtn" @click="modal_ok"  ><i class="iconfont icon-baocun" style="margin-right:5px;"></i>保存</el-button>
            </div>
        </div>
        <!-- 人员新增 -->
        <el-dialog class="pub_dialog"  :visible.sync="perModel" width="1100px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{perFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div> 
            <div style="max-height:88vh;overflow-y:auto;padding-bottom:100px;">
                <el-card v-for="(gpitem,index) in perGpArr" :key="index" style="margin-bottom:15px;">
                    <p slot="header" style="color:#5cadff;height:14px;line-height:14px ">
                        <span style="font-weight:600;font-size:14px;color:#5cadff">{{gpitem.gpnm}} </span>        
                    </p>
                    <Form :model="perRow" :label-width="110" ref="perForm"  label-position="right" inline  :rules="ruleValidate">  
                        <span v-for="(item,index) in gpitem.gplist" :key="index" >
                            <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                  
                                <el-date-picker type="date"  v-if="item.EDCSS==='date'" v-model="perRow[item.FLDNO]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                                <el-input v-enter-number  v-else-if="item.EDCSS==='number'" v-model="perRow[item.FLDNO]" style="width:100%" ></el-input>   
                                <el-checkbox border v-else-if="item.EDCSS==='checkbox'" true-label="Y"  false-label="N" v-model="perRow[item.FLDNO]" style="width:100%"></el-checkbox> 
                                <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false "  v-model="perRow[item.FLDNO]"></el-input>
                            </FormItem>
                        </span>
                    </Form>
                </el-card>
            </div>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; " >
                <span style="float:right">               
                    <el-button type="info" @click="perModel=false" size="small"  icon="iconfont icon-shanchu">  取消</el-button>
                    <el-button   @click="perOk" size="small" type="primary" style="margin-left:30px;" icon="iconfont icon-baocun"  > 确定 </el-button> 
                </span>
            </div>
        </el-dialog> 
        <!-- 银行新增 -->
        <el-dialog class="pub_dialog"  :visible.sync="bankWin" width="1200px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">明细资料</span>
            </div> 
            <div style="max-height:88vh;overflow-y:auto;padding-bottom:100px;">
                <Form :model="bankRow" :label-width="110" ref="perForm"  label-position="right" inline  :rules="ruleValidate">  
                    <span v-for="(item,index) in bankCol" :key="index" >
                        <FormItem  :class=" 'item-width'" :prop="item.key"  v-if="item.fhide==='N'">                  
                            <template slot="label"><span style="color:red;margin-right:5px" v-if="item.key=='PAYNO'||item.key=='PAYNM'">*</span>{{item.title}}</template>
                            <el-date-picker type="date"  v-if="item.edcss==='date'" v-model="bankRow[item.key]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                            <el-select v-else-if="item.key=='PAYTY'" v-model="bankRow[item.key]" style="width:100%">
                                <el-option label="对公账户" value="对公账户"></el-option>
                                <el-option label="对私账户" value="对私账户"></el-option>
                            </el-select>
                            <el-checkbox border v-else-if="item.edcss==='checkbox'" true-label="Y"  false-label="N" v-model="bankRow[item.key]" style="width:100%"></el-checkbox> 
                            <el-input  type="text" v-else   v-model="bankRow[item.key]"></el-input>
                        </FormItem>
                    </span>
                </Form>
            </div>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; " >
                <span style="float:right">               
                    <el-button type="info" @click="bankWin=false" size="small"  icon="iconfont icon-shanchu">  取消</el-button>
                    <el-button   @click="bankOk" size="small" type="primary" style="margin-left:30px;" icon="iconfont icon-baocun"  > 确定 </el-button> 
                </span>
            </div>
        </el-dialog> 
        <!-- 上传进度 -->
        <el-dialog :visible.sync="videoFlag" width="150px" append-to-body :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'30px'}">
                <span style="line-height:30px;height:30px;font-size:16px;padding-left:5px;">正在上传中...</span>
            </div>
            <el-progress   type="circle" :percentage="videoUploadPercent"  ></el-progress>  
        </el-dialog>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
            </div>
            <div style=" margin:15px 10px">{{prompt}}</div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
        <!-- 预览图片 -->
        <el-dialog :visible.sync="showViewer" width="950px" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
                <span style="line-height:35px;height:35px;font-size:16px;padding-left:5px;">图片预览</span>
            </div>
            <el-image fit="scale-down" :src="listurl"  style="width:99%;height:400px;margin-top:5px;"></el-image> 
        </el-dialog>
 
    </div>
</template>
<script>
    import { getTabColOrData,getBaseData } from '../../api/user';
    import { paramDataOrder,paramBase,countryArray,getDate,baseCascade,paramData} from '../../api/Select'
    export default {
    name:"supplier_info",
    data() {
        return {
            mailaddr:'',
            mailcode:'',
 
            bankWin:false,
            videoFlag:false,
            promptWin:false,
            prompt:'',
            videoUploadPercent:0,
            supcount236:0,
            supcount237:0,
            supcount19597:0,
            supcount19598:0,
            servCnt:0,
            addtype:'',
            selectimageTy:'',
            imageTypeArr:[],//图片类型
            loading:false,
            dataCount:0,
            stfgSet:'ALL',
 
            perModel:false,
            perFlag:'create',
            bankFlag:'create',
            specModel:false,
            bankRow:{},
            countryArr:[], //级联数据源
            videoSrc:'',
            isShow:true,
            ruleValidate: {
               //"FIRSTNM":[{"required":true,"message":"联系人姓氏不能为空","trigger":"change"}],
               "EMPNM":[{"required":true,"message":"联系人姓名不能为空","trigger":"change"}],
               "TELNO":[{"required":true,"message":"手机号码不能为空","trigger":"change"}]
            },
            custRule: {},
            prdtyArr:[],  //销售产品级联数据
            type:'create',
            multiprop:{ multiple: true },
            cascadeKey:1,
            specFlag:'create',
            paramTree:'',
            imgSrc:'',
            LANG:'',
            Accept: "png,jpg,jpeg", //上传文件格式限制
            
            treeData:[] ,//  树数据 
            payCol:[], // 
            tabFrmCol:[], //产品组表单列
            v_idno:'',
            uploadData:{}, //上传数据
            matRow:{}, //产品行
            specRow:{}, //规格行
            imgRow:{}, //图片行
            perRow:{}, //价格行
            imgType:'',  // 
 
            selparam:'SUPNM',// select 绑定值
            textparam:'', // input 绑定值
            perGpArr:[], // 联络人表单分组信息
            defaultProps: 
            {  //el-tree 默认字段属性
                children: 'children',
                id:'id',
                icon:'icon',
                label: 'label'
            },
            saveFlag:true,
            bankCol:[],
            bankData:[],
 
            listurl:'',//放大的图片
            videoData:[] , //图片视频数据
            perCol:[] , //联系人字段列数据
            perData:[] , //联系人数据
            ordData:[] , //历史订单数据
            lineArr:[], //线索来源
            v_url:this.$store.state.queryUrl , //api请求路径     
 
            v_idseq:'',
            v_formurl:this.$store.state.baseUrl+'user/getFormGroup', //多记录查询 like模式  
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            input_hold:'请输入内容...',
            crcyArr:[], //货币
            payArr:[],
            cltyArr:[], //供应商类别
            lvlArr:[],
            corpArr:[], //企业类型
            stfgArr:[], // 
            fileName:'',
            operArr:[],
            oper:'like',
            delImg:[] ,//删除记录数组
            rowIndex:'-1',
            allData:[] , //保存产品所有数据
            pageSize:30,
            pageNum:1,
            filety:'',
            showViewer:false,//打开预览/关闭
            tflag:'create',
            pageIndex:1,
            rangeNumber:20,
            notNull:[],
            stfgBool:true,
            bankIndex:'',
            delBank:[],
            delPer:[],
            uploadUrl:this.$store.state.baseUrl +'oss/fileUpload?username='+this.v_username+'&password='+this.v_password,
        }
    },
    watch: {
    },
    components: {
 
    },
    computed: {
        scrollerHeight: function() {
            return (window.innerHeight - 85) + 'px';
        }, 
        subHeight: function() {
            return (window.innerHeight - 115) + 'px';
        }, 
        tabWidth: function() {
            return (window.innerWidth - 110) + 'px';
        }, 
    },
    mounted () {   
        console.log('username:'+this.v_username)    
        //供应商类别
        baseCascade('235').then((res)=>{
            this.cltyArr =res.data
        }); 
        //操作符
        paramData('PARENTNO','41113','V_SYS_BASE').then((res)=>{
            this.operArr =res.data.result
        })
        //销售产品
        paramData('lvl','3','V_PRD_CATEGORY_LVL3').then((res)=>{
            this.prdtyArr = res.data.result
        })  
        //企业性质
        paramBase('19709','v_sys_base').then((res)=>{
            this.corpArr =res.data.result
        });
        //记录状态
        paramBase('164','v_sys_base').then((res)=>{
            this.stfgArr =res.data.result
        });
        //线索来源
        paramBase('19777','v_sys_base').then((res)=>{
            this.lineArr =res.data.result
        });
 
        //currency来源
        paramBase('19665','v_sys_base').then((res)=>{
            this.crcyArr =res.data.result
        });
        //贸易方式
        paramBase('19589','v_sys_base').then((res)=>{
            this.payArr =res.data.result
        });
        //国家
        countryArray().then((res)=>{
            this.countryArr =res.data
        });
        paramDataOrder('PARENTNO','39167','v_sys_base',' AND STFG NOT IN (\'X\')').then((res)=>{
            this.imageTypeArr =res.data.result
        });
        //供应商资料分组 
        getTabColOrData(this.v_formurl,this.v_username,this.v_password,'v_sys_form_fld','idseq','57482','gpnm,gpsort','','','',' order by gpsort').then((res) => {
            this.tabFrmCol = res.data.result             
        });
        //联络资料分组 
        getTabColOrData(this.v_formurl,this.v_username,this.v_password,'v_sys_form_fld','idseq','38822','gpnm,gpsort','','','',' order by gpsort').then((res) => {
            this.perGpArr = res.data.result             
        })
        //动态生成表单验证规则
        getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','57482','fldty','HEAD','NOTNULL','Y').then((res) => {
            let ruleObj={}
            let ruleVal=[]
            this.notNull = res.data
            res.data.forEach(item => {
                let v_fldnm =item.FLDNM
                let obj1={required:true,message:v_fldnm+'不能为空'}
                ruleVal =[]
                ruleVal.push(obj1)
                ruleObj[item.FLDNO]=ruleVal                  
            });
            this.custRule =ruleObj
        })
    },
    created () {  
 
      this.getTabCol() //产品表列字段
      this.queryData()
    },
    methods: { 
        sendCode(){
            this.$axios({   
                method: 'post',
                url: this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq',p_value:'57238',p_table:'v_CONTENT_SET'},
            }).then(res=>{ 
                this.$axios({
                    method: 'post',
                    url: this.$store.state.baseUrl+'auth/login/sendMail',
                    data: {p_user:'mailreg',mailaddr:this.mailaddr,title:res.data.result[0]['TITLE'],p_note:res.data.result[0]['NOTE']},
                }).then(res=>{      
                    if(res.data.code ==='200')  {      
                        this.promptWin=true 
                        this.prompt ='已成功发送，请到邮箱中查看，谢！'
                         
                    }else{
                        this.promptWin=true
                        this.prompt= res.data.message
                    }        
                }) 
            })
        },
 
        //注册供应商
        mainPage(){
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self');
        },
        loadMore(n) {
            return () => this.rangeNumber += 10 //每次滚动到底部可以新增条数 
        },
        getRowKeys(row){
            return row.IDSEQ
        },
        showClick(val){
            this.filety =val
        },
        //上传显示进度
        handleProgress(event,file,fileList){
            this.videoFlag = true
            this.videoUploadPercent = Math.floor(event.percent)
        },
 
        queryData(){
 
            this.qrySupData()
        },
        bankEdit(row,index){
            this.bankWin=true
            this.bankRow =JSON.parse(JSON.stringify(row))
            this.bankFlag='edit'
            this.bankIndex=index
        },
        //联络记录 编辑、删除
        perEdit(row,index){
            this.rowIndex =index
            this.perModel =true
            this.perFlag='edit'  
            this.perRow =JSON.parse(JSON.stringify(row))  
        },
 
        bankOk(){
            if(!this.bankRow.PAYNO){
                this.prompt='银行账号不能为空'
                this.promptWin=true               
            }else if(!this.bankRow.PAYNM){
                this.prompt='帐户名称不能为空'
                this.promptWin=true               
            }else{
                this.bankWin =false 
                if (this.bankFlag==='edit' ){ //编辑时要替换数组记录
                    this.bankData.splice(this.bankIndex,1,this.bankRow)
                }else{
                    this.bankData.push(this.bankRow)
                }
            }
        },
        perOk(){
            var verify = /^1[3456789]\d{9}$/;
            if(!this.perRow.EMPNM){
                this.prompt='联系人姓名不能为空'
                this.promptWin=true               
            }else if(!this.perRow.TELNO){
                this.prompt='手机号码不能为空'
                this.promptWin=true               
            }else if(!verify.test(this.perRow.TELNO)){
                this.prompt='手机号码格式错误, 请重新输入'
                this.promptWin=true 
            }else if(!verify.test(this.perRow.TELNO2) && this.perRow.TELNO2){
                this.prompt='手机号码格式错误, 请重新输入'
                this.promptWin=true             
            }else{
                this.perModel =false 
                if (this.perFlag==='edit' ){ //编辑时要替换数组记录
                    this.perData.splice(this.rowIndex,1,this.perRow)
                }else{
                    this.perData.push(this.perRow)
                }
            }
        },
        //保存记录
        modal_ok(){           
            let prdty_='' ,vm=this
            let area_=''
            let reqprdty_=''
            this.notNull.some(item=>{  //forEach 没找到跳出循环，some：return true 跳出lop ,every：return false 跳出
                if (!this.matRow[item.FLDNO] ){
                    this.stfgBool =false 
                    this.promptWin=true
                    this.prompt=item.FLDNM+'不能为空'
                    return true
                }else{
                    this.stfgBool =true  
                }
            })
            if (this.stfgBool) {
        
                // 销售产品、需求产品、供应商类型、国家 这些是值是数组形式，存储时以字符串形式，显现时要转换为数组
                if (typeof(this.matRow['PRDTY'])!=='undefined' && this.matRow['PRDTY']!==''){
                    prdty_ =this.matRow['PRDTY'].join(',') 
                }
                if (typeof(this.matRow['REQPRDTY'])!=='undefined' && this.matRow['REQPRDTY']!==''){
                    reqprdty_ =this.matRow['REQPRDTY'].join(',') 
                }
                if (typeof(this.matRow['AREA'])!=='undefined' && this.matRow['AREA']!==''){
                    area_ =this.matRow['AREA'].join(',') 
                }

                Object.assign(this.matRow,{PRDTY:prdty_,REQPRDTY:reqprdty_,AREA:area_,SUPTY:'57483'} )
                this.$axios({  //传入 p_frmid 参数是为了生成 表单 的编码
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username+'&password='+this.v_password,
                    data: {p_json:JSON.stringify(this.matRow), p_table:'bs_sup',frmid:'33'},
                }).then(res=>{      
                    if(res.data.result ==='OK')  {          
                        this.v_idseq=res.data.idseq 
                        //银行资料
                        if(this.bankData.length>0){
                            for (let k=0; k<this.bankData.length; k++){
                                this.$axios({
                                    method: 'post',
                                    url:vm.$store.state.baseUrl+'sysprivs/tableBodySave?username='+this.v_username+'&password='+this.v_password ,
                                    data: {p_json:JSON.stringify(Object.assign(vm.bankData[k],{'IDSEQ':this.v_idseq })), p_table:'BS_SUPBANK',frmid:''},
                                }).then(res=>{      
                                    if(res.data.result !=='OK')  {          
                                        this.promptWin=true
                                        this.prompt=res.data.result
                                    }        
                                }) 
                            }
                        }
                        //联系人资料
                        if(this.perData.length>0){
                            getBaseData(this.$store.state.nologinUrl, 'idseq',this.v_idseq,'','','bs_sup','').then(res=>{
                                this.v_idno =res.data.result[0]['IDNO']
                                for (let k=0; k<this.perData.length; k++){
                                    this.$axios({
                                        method: 'post',
                                        url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username+'&password='+this.v_password ,
                                        data: {p_json:JSON.stringify(Object.assign(vm.perData[k],{'IDSEQ':'',SUPNO:this.v_idno})), p_table:'HR_EMP',frmid:'107'},
                                    }).then(res=>{      
                                        if(res.data.result !=='OK')  {          
                                            this.promptWin=true
                                            this.prompt=res.data.result
                                        }        
                                    }) 
                                }
                            })
                        }
                        //图片记录保存 
                        this.$axios({   
                            method: 'post',
                            url:this.$store.state.baseUrl+'sysprivs/batDtlSave?username='+this.v_username+'&password='+this.v_password,
                            data: {p_json:JSON.stringify(this.videoData), p_table:'BS_SUPIMG',p_idseq:this.v_idseq,p_action:'S' },
                        })
                        //图片记录删除
                        if (this.delImg.length>0) {
                            this.$axios({   
                                method: 'post',
                                url:this.$store.state.baseUrl+'sysprivs/batDtlSave?username='+this.v_username+'&password='+this.v_password,
                                data: {p_json:JSON.stringify(this.delImg), p_table:'BS_SUPIMG',p_idseq:this.v_idseq,p_action:'D' },
                            })
                        }
                        this.queryData()
                    }else{
                        this.prompt=res.data.result
                        this.promptWin=true 
                    }        
                })    
            }   
        },
        toggleLeft(){
            this.isShow =!this.isShow
        },
        matOk(){

        },
        bankDel(row,index){
            this.$confirm('你确定要删除此行记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{ 
                this.$axios({
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username+'&password='+this.v_password,
                    data: {p_key:'idseq',p_value:row['IDSEQ'],p_key2:'lstseq',p_value2:row['LSTSEQ'],p_table:'bs_supbank'},
                }).then(res=>{ 
                    this.bankData.splice(index,1)
                })
            }).catch(()=>{})
        },
        perDel(row,index){
            this.$confirm('你确定要删除此行记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.$axios({
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username+'&password='+this.v_password,
                    data: {p_key:'idseq',p_value:row['IDSEQ'],p_key2:'lstseq',p_value2:row['LSTSEQ'],p_table:'bs_supemp'},
                }).then(res=>{ 
                    this.perData.splice(index,1)
                })
                
            }).catch(()=>{})
        },
        // 变更状态记录
        cfmRec(row,val){
            let v_comm=''
            if (val==='Y'){
                v_comm ='审核'
            }else if (val==='X'){
                v_comm ='作废'
            }else{
                v_comm ='反审核'
            }
            this.$confirm('你确定要'+v_comm+'此行记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                var vm=this;
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'user/updateRec?username='+this.v_username+'&password='+this.v_password,
                    data: {p_key:'idseq',p_value:row.IDSEQ,p_table:'BS_SUP',p_cond:' set stfg=\''+val+'\' '},
                }).then(res=>{      
                    if(res.data.code =='200')  {           
                        Object.assign(row,{STFG:val})
                    }else{
                        this.prompt=res.data.result
                        this.promptWin=true 
                    }        
                }) 
            }).catch(()=>{})         
        },
 
        //查询视频数据
        qryImgData(idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_supIMG','idseq',idseq).then((res) => {
                this.videoData=[]
                if (res.data){
                    this.videoData =res.data
                }
            })
        },
        qryBankData(idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_supbank','idseq',idseq).then((res) => {
                this.bankData=[]
                if (res.data){
                    this.bankData =res.data
                }
            })
        },
        bankAdd(){
            this.bankWin =true
            this.bankFlag='create'
            this.bankRow={PAYTY:'对公账户'}
        },
        //联系人新增记录
        perAdd(){
            this.perModel =true
            this.perFlag ='create'
            this.perRow={}
        },
        //文件超出指定文件大小限制的钩子
        handleMaxSize (file) {
            this.prompt =file.name + ' 文件太大, 不能超出2M'
            this.promptWin=true
        },
        //文件格式验证错误时的钩子
        handleFormatError (file) {
            this.prompt='文件格式错误,'+file.name + ' 不正确,请选择 jpg or png' 
            this.promptWin=true
        },
        // 文件上传成功时的钩子
        successImgFile(res,file){
            this.videoFlag = false
            this.videoUploadPercent=0
            this.qryImgData(this.matRow['IDSEQ'])             
        },
        //上传文件之前的钩子
        beforeImgFile (res, file) { 
            if(this.matRow['IDSEQ'])  {          
                this.uploadData = {
                    p_user:this.v_username,
                    p_idseq:this.matRow['IDSEQ'],
                    p_table:'BS_SUPIMG',
                    p_filety:this.filety,
                }
                let promise = new Promise(resolve => {
                this.$nextTick(function() {
                        resolve(true);
                    });
                });
                return promise;  
            }else{
                this.promptWin=true
                this.prompt='请先保存资料后再上传'
            }
        },
        //查询视频数据
        qryvideoData(idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_SUPIMG','idseq',idseq).then((res) => {
                this.videoData=[]
                if (res.data){
                    this.videoData =res.data
                }
            })
        },
        //查询联系人数据
        getPerData(idno){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_SUPEMP','SUPNO',idno).then((res) => {
                this.perData=[]
                if (res.data){
                    this.perData =res.data     
                }
            })
        },
 
        //查询supply信息
        qrySupData(){
            let vm=this
            vm.loading=true
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_bs_SUP','IDNO',this.v_username,'','','','','').then((res) => {
                for (var k=0; k<res.data.length; k++){
                    let obj_={}
                    let row_=res.data[k]
                    let prdty_=''
                    let area_=''
                    let reqprdty_=''
 
                    if (row_.PRDTY){
                        prdty_ =row_['PRDTY'].split(',')
                    }
                    if (row_.AREA){
                        area_ =row_['AREA'].split(',')
                    }
                    if (row_.REQPRDTY){
                        reqprdty_ =row_['REQPRDTY'].split(',')
                    }
 
                    Object.assign(obj_,res.data[k],{'PRDTY':prdty_,'AREA':area_, 'REQPRDTY':reqprdty_ })
                    this.matRow =obj_
                } 
                //查询联系人数据
                this.getPerData(this.v_username)
                //查询视频数据
                this.qryvideoData(res.data[0]['IDSEQ'])
                this.qryBankData(res.data[0]['IDSEQ'])
            })
        },
        //图片预览
        imgPreview(path){
            this.showViewer=true
            this.listurl=path
        },
        //图片删除，操作数组
        imgDel(idseq,lstseq,path,index){
            this.$confirm('你确定要删除此图片吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //发起删除请求
                this.videoData.splice(index, 1); 
                this.delImg.push({IDSEQ:idseq,LSTSEQ:lstseq,PATH:path.replace('https://kreco.oss-accelerate.aliyuncs.com/','')})         
            }).catch(()=>{})
        },
 
        //获取表列头字段
        getTabCol(){
            var vm=this
            //联系人
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','38822','fldty','HEAD2','','',' order by sortby,lstseq').then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.perCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,
                        edcss: res.data[k].EDCSS,
                        fhide: res.data[k].FHIDE,
                        thide: res.data[k].THIDE,
                        disabled: res.data[k].DISABLED,
                        sortable: res.data[k].sortable?true:false,
                    })
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','57482','fldty','HEAD2','','',' order by sortby,lstseq').then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.bankCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,
                        edcss: res.data[k].EDCSS,
                        fhide: res.data[k].FHIDE,
                        thide: res.data[k].THIDE,
                        disabled: res.data[k].DISABLED,
                    })
                }
            })
        },
 
    },
 }
</script>

<style scoped lang="less">
    .bottomdiv{
        background:#e8eaec;
        height:40px;
        line-height:40px;
        margin-top:1px ;
    }
 
    .paybtn:hover{
        background:#ac7c34;
    }
    .paybtn{
        height:40px;
        background:#f87a7a;
        width:90px;
        color:white;
        font-size:18px;
    }
    .item-width  {
        width: 32%;
        height:25px;
    }
    .item-width2  {
        width: 48%;
    }
    .row-width  {
        width: 97%;
    }
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
</style> 
<style lang="less">
    .row-width  {
        width: 98.5%;
    }
    .el-card__header{
        //height:35px;
        vertical-align: middle;
    }
    .el-table {
        th {
            padding: 0 ;
        }
        thead{
            height: 15px !important;
            color:rgb(11, 11, 59);
            font-size:12px;
            background:rebeccapurple;
        }
        td {
            padding: 5px ;
            font-size:11px;
        } 
    }
    .messageBox .el-message-box__content{
        border-top: 1px solid #EBEEF5;
        height: 50px;
    }
    .el-message-box__message p{
        line-height: 25px;
    }
    .messageBox .el-message-box__btns{
        padding: 20px 15px 0;
    }
    .messageBox .el-message-box__header{
        background:#409eff;
    }
    .messageBox .el-message-box__title{
        color:white ;
        font-size:14px;
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
</style>
